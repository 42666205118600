// @import 'sanitize.css/sanitize';

$breakpoint: 50rem;

@font-face {
  src: url("/fonts/enduroweb-regular.woff2") format("woff2"),
    url("/fonts/enduroweb-regular.woff") format("woff");
  font-family: "Enduro";
  font-style: normal;
  font-weight: 400;
  // font-display: swap;
}

@font-face {
  src: url("/fonts/enduroweb-semibold.woff2") format("woff2"),
    url("/fonts/enduroweb-semibold.woff") format("woff");
  font-family: "Enduro";
  font-style: normal;
  font-weight: 600;
  // font-display: swap;
}

*,
::before,
::after {
  box-sizing: border-box;
}

:root {
  // Colours
  --black: #131314;
  --grey: #a0a0a3;
  --white: #fffefc;
  --blue: #00e;
  --cubehelix: #131314, #230f29, #28193d, #27264e, #233659, #1e475e, #1c585c,
    #1f6854, #287449, #3a7d3e, #518237, #6e8337, #8b823f, #a78050, #be8069,
    #ce8286, #d787a5, #d891c1, #d49ed9, #cdaee9, #c7bff2, #c4d0f4, #c6def3,
    #cfeaf0, #dcf3ef, #edfaf3, #fffefc;
  --cubehelix-dark: #131314, #230f29, #28193d, #27264e, #233659, #1e475e,
    #1c585c, #1f6854, #287449, #3a7d3e, #518237;
  --cubehelix-light: #518237, #6e8337, #8b823f, #a78050, #be8069, #ce8286,
    #d787a5, #d891c1, #d49ed9, #cdaee9, #c7bff2, #c4d0f4, #c6def3, #cfeaf0,
    #dcf3ef, #edfaf3, #fffefc;
  --gold: #f7c34f;
  --silver: #d1dce6;
  --bronze: #a78050;
  --merit: #aad1f2;

  // Grid/spacing
  --min-width: 15ch;
  --columns: [img-start about-heading-start] minmax(var(--min-width), 1fr)
    [about-heading-end about-content-start] repeat(5, 0.7fr) [about-content-end
    info-heading-start] minmax(var(--min-width), 1fr) [img-end info-heading-end
    info-content-start info-content-left-start] minmax(var(--min-width), 1fr)
    [info-content-left-end info-content-right-start]
    minmax(var(--min-width), 1fr) [info-content-end info-content-right-end];
  --columns-sm: [img-start info-heading-start about-heading-start
    info-content-left-start] minmax(0, 1fr) [info-heading-end about-heading-end
    info-content-start about-content-start info-content-left-end
    info-content-right-start] repeat(2, minmax(0, 1fr)) [img-end
    info-content-end about-content-end info-content-right-end];
  --gap: 1ch;
  --indent: calc(var(--gap) * 2);
  --divider: 20ch;

  // Font weights
  --bold: 600;

  // Timing
  --duration: 0.4s;

  // Opacity
  // match luminosity of background colour on white images
  --exclusion-white-opacity: 0.91;

  @supports (color: color(display-p3 1 1 1)) {
    --black: color(display-p3 0.0745 0.0745 0.0784);
    --grey: color(display-p3 0.6275 0.6275 0.6392);
    --white: color(display-p3 1 0.9961 0.9882);
    --blue: color(display-p3 0 0 0.9333);
    --cubehelix: color(display-p3 0.0745 0.0745 0.0784),
      color(display-p3 0.1373 0.0588 0.1608),
      color(display-p3 0.1569 0.098 0.2392),
      color(display-p3 0.1529 0.149 0.3059),
      color(display-p3 0.1373 0.2118 0.349),
      color(display-p3 0.1176 0.2784 0.3686),
      color(display-p3 0.1098 0.3451 0.3608),
      color(display-p3 0.1216 0.4078 0.3294),
      color(display-p3 0.1569 0.4549 0.2863),
      color(display-p3 0.2275 0.4902 0.2431),
      color(display-p3 0.3176 0.5098 0.2157),
      color(display-p3 0.4314 0.5137 0.2157),
      color(display-p3 0.5451 0.5098 0.2471),
      color(display-p3 0.6549 0.502 0.3137),
      color(display-p3 0.7451 0.502 0.4118),
      color(display-p3 0.8078 0.5098 0.5255),
      color(display-p3 0.8431 0.5294 0.6471),
      color(display-p3 0.8471 0.5686 0.7569),
      color(display-p3 0.8314 0.6196 0.851),
      color(display-p3 0.8039 0.6824 0.9137),
      color(display-p3 0.7804 0.749 0.949),
      color(display-p3 0.7686 0.8157 0.9569),
      color(display-p3 0.7765 0.8706 0.9529),
      color(display-p3 0.8118 0.9176 0.9412),
      color(display-p3 0.8627 0.9529 0.9373),
      color(display-p3 0.9294 0.9804 0.9529), color(display-p3 1 0.9961 0.9882);
    --cubehelix-dark: color(display-p3 0.0745 0.0745 0.0784),
      color(display-p3 0.1373 0.0588 0.1608),
      color(display-p3 0.1569 0.098 0.2392),
      color(display-p3 0.1529 0.149 0.3059),
      color(display-p3 0.1373 0.2118 0.349),
      color(display-p3 0.1176 0.2784 0.3686),
      color(display-p3 0.1098 0.3451 0.3608),
      color(display-p3 0.1216 0.4078 0.3294),
      color(display-p3 0.1569 0.4549 0.2863),
      color(display-p3 0.2275 0.4902 0.2431),
      color(display-p3 0.3176 0.5098 0.2157);
    --cubehelix-light: color(display-p3 0.3176 0.5098 0.2157),
      color(display-p3 0.4314 0.5137 0.2157),
      color(display-p3 0.5451 0.5098 0.2471),
      color(display-p3 0.6549 0.502 0.3137),
      color(display-p3 0.7451 0.502 0.4118),
      color(display-p3 0.8078 0.5098 0.5255),
      color(display-p3 0.8431 0.5294 0.6471),
      color(display-p3 0.8471 0.5686 0.7569),
      color(display-p3 0.8314 0.6196 0.851),
      color(display-p3 0.8039 0.6824 0.9137),
      color(display-p3 0.7804 0.749 0.949),
      color(display-p3 0.7686 0.8157 0.9569),
      color(display-p3 0.7765 0.8706 0.9529),
      color(display-p3 0.8118 0.9176 0.9412),
      color(display-p3 0.8627 0.9529 0.9373),
      color(display-p3 0.9294 0.9804 0.9529), color(display-p3 1 0.9961 0.9882);
    --gold: color(display-p3 0.9686 0.7647 0.3098);
    --silver: color(display-p3 0.8196 0.8627 0.902);
    --bronze: color(display-p3 0.6549 0.502 0.3137);
    --merit: color(display-p3 0.6667 0.8196 0.949);
  }
}

html {
  font-size: 0.825em; // set 1rem
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  margin: 0;
  font-family: "Enduro", system-ui;
  line-height: 1.1;
  color: var(--white);
  font-variant-numeric: proportional-nums;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  background-color: var(--black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: 1rem;
}

h1,
h2,
h3 {
  font-weight: var(--bold);
}

h4,
h5,
h6 {
  font-weight: normal;
}

p {
  margin: 0;
}

a {
  font-weight: var(--bold);

  &:link,
  &:visited {
    color: var(--white);
    text-decoration: none;
  }
}

a:hover {
  text-decoration: underline;
}

img,
video {
  display: block;
}

ul,
ol,
dl {
  margin: 0;
  padding: 0;
}

button {
  font-family: inherit;
}

.gap {
  border-style: none;
  margin: 0 0 var(--gap);
}

em,
cite {
  font-style: normal;
}

.header {
  min-height: 66.67vh;
  display: grid;
  grid-template-columns: var(--columns-sm);
  grid-template-rows: 2fr 7fr 5fr; // divide rows between light and dark cubehelix colours
  gap: var(--gap);
  padding: var(--gap);
  background-color: var(--white);
  background-image: linear-gradient(to top, var(--cubehelix), var(--white) 99%);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  @media all and (min-width: $breakpoint) {
    grid-template-columns: var(--columns);
    grid-template-rows: 16fr 10fr; // divide rows between light and dark cubehelix colours
  }

  & a:link,
  & a:visited {
    color: var(--blue);
  }
}

.header-unit {
  color: var(--black);
}

.header-title {
  grid-column: about-heading;

  h1 {
    position: fixed;
    z-index: 2;
    color: var(--white);
    mix-blend-mode: exclusion;
  }
}

.header-about {
  grid-column: about-content;

  p {
    max-width: 60ch;
  }
}

.social-profiles {
  & > li {
    display: inline-block;
    list-style-type: none;
    margin-right: var(--gap);
  }
}

.social-link {
  &:link,
  &:visited {
    font-weight: normal;
  }

  &::after {
    content: "\2197"; // ↗
    font-weight: normal;
  }
}

.header-client-heading {
  grid-column: info-heading;
}

.header-client-content {
  grid-column: info-content;
}

.client-list {
  columns: var(--min-width) 2;
  column-gap: var(--gap);

  & > li {
    list-style-type: none;
    break-inside: avoid;
    padding: 0 var(--gap) 0 var(--indent);
    text-indent: calc(var(--indent) * -1);

    &:hover {
      padding-left: 0;
      text-indent: 0;
      text-align: justify;
      text-align-last: justify;
      text-justify: inter-character;
    }
  }
}

.project {
  display: grid;
  grid-template-columns: var(--columns-sm);
  grid-template-rows: [project-title-start project-category-start] max-content [project-title-end project-category-end img-start] max-content [img-end project-description-start counter-start] min-content [project-description-end project-metadata-start] max-content [project-metadata-end counter-end];
  gap: var(--gap);
  padding: var(--gap);
  margin-top: var(--divider);

  @media all and (min-width: $breakpoint) {
    grid-template-columns: var(--columns);
    grid-template-rows: [img-start project-title-start project-category-start] 1fr [project-title-end project-category-end project-description-start] max-content [project-description-end project-metadata-start counter-start] max-content [img-end project-metadata-end counter-end];
  }
}

.project-title {
  grid-column: info-content-left;
  grid-row: project-title;
  padding-right: var(--gap);
  align-self: end;

  @media all and (min-width: $breakpoint) {
    align-self: stretch;

    & > :first-child {
      position: sticky;
      top: var(--gap);
    }
  }
}

.project-category {
  grid-column: info-content-right;
  grid-row: project-category;
  padding-right: var(--gap);
  font-weight: normal;
  text-transform: lowercase;
  align-self: end;

  @media all and (min-width: $breakpoint) {
    align-self: start;
  }
}

.project-metadata {
  grid-column: info-content-right;
  grid-row: project-metadata;
  padding-right: var(--gap);
  align-self: end;

  a {
    white-space: nowrap;
  }
}

.project-description {
  grid-column: info-content;
  grid-row: project-description;
  padding-right: var(--gap);
  align-self: end;

  p + p {
    margin-top: var(--gap);
  }
}

.project-awards {
  list-style-type: none;
  margin-top: var(--gap);
}

.project-award {
  --project-award-glyph-width: calc(var(--gap) / 3 * 4);
  --project-award-glyph-margin: calc(var(--gap) / 3);
  --project-award-glyph-total-width: calc(
    var(--project-award-glyph-width) + var(--project-award-glyph-margin)
  );
  margin-inline-start: var(--project-award-glyph-total-width);
  text-indent: calc(var(--project-award-glyph-total-width) * -1);

  &::before {
    content: "⚫";
    display: inline-block;
    width: var(--project-award-glyph-width);
    margin-inline-end: var(--project-award-glyph-margin);
    color: var(--silver);
    text-transform: uppercase;
    text-align: center;
    text-indent: 0;
  }
}

.project-award-square {
  &::before {
    content: "⏹";
  }
}

.project-award-triangle {
  &::before {
    content: "▲";
  }
}

.project-award-diamond {
  &::before {
    content: "\2666";
  }
}

.project-award-gold {
  &::before {
    color: var(--gold);
  }
}

.project-award-silver {
  &::before {
    color: var(--silver);
  }
}

.project-award-bronze {
  &::before {
    color: var(--bronze);
  }
}

.project-award-wood {
  &::before {
    color: var(--bronze);
  }
}

.project-award-merit {
  &::before {
    color: var(--merit);
  }
}

// .project-award-show {
//   white-space: nowrap;
// }

.project-images {
  grid-column: img;
  grid-row: img;

  & > .project-image + .project-image {
    // add margin between images when carousel is deactivated
    margin-top: var(--gap);
  }
}

.carousel {
  position: relative;
  z-index: 1;
}

.carousel-content {
  display: flex;
  flex: 1 0 100%; // fit to full width of container
  align-items: stretch;
  // gap: var(--gap);
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  z-index: 0;

  scrollbar-width: none; // standard property, Firefox
  -ms-overflow-style: none; // Edge

  &::-webkit-scrollbar {
    display: none; // Safari, Chrome
  }
}

.carousel-item {
  flex: 1 0 calc(100% + 1px); // compensate for rounding errors
  scroll-snap-align: start;
  margin: 0;
  padding: 0;
  // width: 100%;
  // height: 100%;
  position: relative;

  & img,
  & video {
    object-fit: cover;
    width: 100%;
    // width: calc(100% + 1px);
    height: 100%;
  }
}

.carousel-item-caption {
  position: absolute;
  bottom: var(--gap);
  left: var(--gap);
  z-index: 2; // above nav buttons
  text-align: left;
  opacity: 0;
  transition: opacity var(--duration);
  color: var(--white);
  // mix-blend-mode: exclusion;

  .carousel:hover & {
    opacity: var(--exclusion-white-opacity);
  }
}

// .carousel-nav {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   display: grid;
//   grid-template-columns: minmax(10ch, 1fr) 3fr minmax(10ch, 1fr);
// }

.carousel-nav-btn {
  // remove default button styles
  background-color: transparent;
  border-style: none;
  outline-style: none;

  position: absolute;
  top: 0;
  width: 25%;
  min-width: 1em;
  height: 100%;
  z-index: 1;
  margin: 0;
  padding: 0 var(--gap);
  cursor: pointer;
  font-family: system-ui;
  font-size: 5rem;
  font-weight: 200;
  color: var(--white);
  mix-blend-mode: exclusion;
  opacity: 0;
  transition: all var(--duration);

  touch-action: none;

  &:hover {
    opacity: var(--exclusion-white-opacity);
  }

  @media (any-hover: none) {
    // display: none; // hide on touch devices
    -webkit-tap-highlight-color: transparent;

    &:hover {
      opacity: 0;
    }
  }
}

.carousel-prev {
  left: 0;
  text-align: left;
  // background-image: linear-gradient(to left, #fffefc00, ease-in-out, #fffefcff);
}

.carousel-next {
  right: 0;
  text-align: right;
  // background-image: linear-gradient(to right, #fffefc00, ease-in-out, #fffefcff);
}

.carousel-counter {
  // position: absolute;
  // bottom: 0;
  // left: 100%;
  // width: 10ch;
  // padding-left: var(--gap);
  grid-column: info-content-left;
  grid-row: counter;
  align-self: start;
  font-variant-numeric: tabular-nums;
  display: flex;
  flex-wrap: wrap;
  gap: 0 0.333ch;

  @media all and (min-width: $breakpoint) {
    align-self: end;
  }
}

.carousel-counter-number {
  display: block;
  font-weight: normal;
  opacity: 0.5;
  // transition: opacity var(--duration);

  &:hover {
    opacity: 1;
    text-decoration: none;
  }

  &:nth-child(n + 10) {
    font-variant-numeric: proportional-nums;
  }

  // &:only-child {
  //   display: none;
  // }
}

.carousel-counter-number-active {
  opacity: 1;
}

// .carousel-counter-index {
//   display: inline-block;
//   text-align: right;
//   width: 1ch;
// }

// .carousel-counter-divider,
// .carousel-counter-total {
//   display: inline-block;
// }

.project-nda {
  .project-image:first-child::before {
    color: var(--white);
    content: "NDA";
    cursor: not-allowed;
    display: block;
    font-size: 20vw;
    font-weight: var(--bold);
    line-height: 0;
    mix-blend-mode: exclusion;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 48%;
    transition: all var(--duration);
    width: 100%;
  }

  .project-image:hover:first-child::before {
    opacity: var(--exclusion-white-opacity);
  }
}

.footer {
  // min-height: 66.67vh;
  display: grid;
  grid-template-columns: var(--columns-sm);
  grid-template-rows: repeat(4, max-content);
  grid-auto-flow: dense;
  gap: var(--gap);
  padding: var(--divider) var(--gap)
    calc(env(safe-area-inset-bottom, 0) + var(--gap));
  margin-top: var(--divider);
  background-image: linear-gradient(to bottom, var(--cubehelix-dark));

  @media all and (min-width: $breakpoint) {
    grid-template-columns: var(--columns);
    grid-template-rows: repeat(2, max-content);
  }
}

.cv-heading {
  grid-column: about-heading;
  grid-row: span 1;
}

.cv-content,
.cv-awards {
  grid-column: about-content;
  grid-row: span 1;
  padding-bottom: var(--gap);
  list-style-type: none;

  li:not(:first-child) > h3 {
    margin-top: var(--gap);
  }
}

.footer-unit-heading {
  grid-column: info-heading;
}

.footer-unit-content {
  grid-column: info-content;
  padding-bottom: var(--gap);
}

.photo-credits {
  dt:not(:first-child) {
    margin-top: var(--gap);
  }

  dd {
    padding: 0 0 0 1em;
    margin: 0;
    text-indent: -1em;

    &:before {
      content: "—";
      text-transform: uppercase;
    }
  }
}

.ll {
  grid-column: info-content;
  align-self: end;
  color: var(--black);
  padding-bottom: var(--gap);

  @media all and (min-width: $breakpoint) {
    grid-column: 1 / info-heading-start;
    grid-row: -1 / -2;
  }
}

.lat,
.long {
  display: inline-block;
  width: 9ch;
}
